@import "../../scss/palette";
@import "../../scss/antdesign";

.insurance-page {
  .insurance-page-full {
    text-align: center;
    .table {
      margin: 0 auto;
      border: $primary-border;
      max-width: 600px;
      thead {
        background-color: $light-gray-color2;
        th {
          font-size: 1em;
          padding: 5px;
        }
      }
      tbody {
        tr {
          &:hover {
            background: $gray-color;
          }
        }
      }
      th,
      td {
        font-size: 1em;
        width: 50%;

        border-top: $primary-border;
      }
    }
  }
}
