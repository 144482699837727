@import "../../scss/palette";
.cash-needed {
  padding-bottom: 10px;
  .list-mortgages {
    width: 100%;

    .ant-table-cell:last-child {
      text-align: right;
    }
  }

  .question {
    display: initial;
    .anticon {
      font-size: 1.2em;
      vertical-align: 0;
    }
  }
}
