@import "../../scss/palette";
.Rebate {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  text-align: right;
  width: 100%;

  table {
    margin: 10px;

    td {
      text-align: left;
    }
  }
}
