//colors:
$primary-grey: #b1b1b8;
$primary-green: #a6e332;
$primary-blue: #2b98c5;

$black-color: #000000;
$blue-color: #2b98c5;
$blue-color-transparent: #2b97c52f;
$blue-light-color: #409bf0; // #39a0fd;
$blue-light-color2: #7fc0eb;
$violet: #9f52cc;
$dark-gray-color: #5b5b5b;
$dirty-black-color: #414141;
$dirty-white: #fefefe;
$dirty-grey: #252525;
$gray-color: #d6d8d1;
$green-color: #7bcf7b;
$green-soft-color: #ccf0cc;
$orange: #81b526;
$dark-orange: #964d0a;
$orange-light: #d4f094;
$red-color: #ff0000;
$red-soft-color: #ff4d4f;
$red-soft2-color: #ff7875;
$white-color: #ffffff;
$white-color2: #fdfdfd;
$light-gray-color: #f8f8f8;
$light-gray-color2: #efefef;
$text-input: #696969;
$gold-color: gold;
$light-green-color: #a6e332;
$middle-grey-color: #8d8d8c;

$gray-gradient: linear-gradient(to right, #fff 0%, #f0f2ec 50%, #fff);

// slider
$bg-webkit: -webkit-gradient(
  linear,
  50% 0%,
  50% 100%,
  color-stop(0%, rgba(0, 0, 0, 0.13)),
  color-stop(100%, $white-color)
);
$bg-moz-linear: -moz-linear-gradient(rgba(0, 0, 0, 0.13), $white-color);
$bg-webkit-linear: -webkit-linear-gradient(rgba(0, 0, 0, 0.13), $white-color);
$bg-linear: linear-gradient(rgba(0, 0, 0, 0.13), $white-color);

// slider -hover
$bg-webkit-hover: -webkit-gradient(
  linear,
  50% 0%,
  50% 100%,
  color-stop(0%, $blue-light-color),
  color-stop(100%, $white-color)
);
$bg-moz-linear-hover: -moz-linear-gradient($blue-light-color, $white-color);
$bg-webkit-linear-hover: -webkit-linear-gradient(
  $blue-light-color,
  $white-color
);
$bg-linear-hover: linear-gradient($blue-light-color, $white-color);

$transparent: transparent;

// borders
$primary-border: 1px solid $primary-grey;
$dotted-border: 1px dotted rgba(0, 0, 0, 0.85);
$checkbox-inner: 3px solid $green-color;

$blue-border: 1px solid $blue-color;
$gold-border: 1px solid $gold-color;
$blue-light-border: 1px solid $blue-light-color;
$dotted-border: 1px dotted #252525;
$white-border: 1px solid rgba(255, 255, 255, 0.5);
$bold-transparent-border: 5px solid $transparent;
$bold-blue2-border: 5px solid $blue-light-color2;
$gray-border: 1px solid #8d918d;
$grey-border: 1px solid #f0f0f0;
$green-border: 1px solid green;
$green-dotted-border: 1px dotted green;
$red-dotted-border: 1px dotted $red-color;
$input-border: 1px solid #ebebeb;
$transparent-border: 0px solid rgba(255, 255, 255, 0.5);
$tooltip-border: 1px solid rgba(0, 0, 0, 0.2);

// backgrounds:
$menu-bg: #5b5b5b;
$menu-bg-mobile: #5b5b5b;
$blue-light-bg: rgba(43, 151, 197, 0.4);
$white-light-bg: rgba(255, 255, 255, 0.8);
$blue-soft-bg: rgba(43, 151, 197, 0.07);
$green-bg: rgb(134, 206, 0);
$green-light-bg: rgba(60, 224, 77, 0.2);
$red-light-bg: rgba(245, 136, 136, 0.2);
$success-color: #cefad7;
$success-color2: #87e59a;
$alert-color: #fce1e1;
$alert-color2: #f5839b;
$tooltip-bg: rgba(0, 0, 0, 0.8);
$soft-black: rgba(0, 0, 0, 0.1);
$card-hover-color: #59e8000e;
$border-radius: 5px;

// font-size
$font-size-09: 0.9em !important;
