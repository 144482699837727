@import "./palette";
@import "./slider";
// Head Menu
.ant-layout-header {
  height: 1px;
}
.ant-layout-header,
.ant-layout {
  background: $transparent;
  .ant-menu {
    background: $transparent;
    line-height: 64px;
    border-bottom: $transparent-border;
  }
}
.ant-select-selection-item {
  width: 100%;
  text-align: left;
}

// LeftPart
.div-wrapper {
  .ant-typography mark {
    background-color: $orange-light;
  }
  .ant-collapse {
    border: $primary-border;
    border-radius: 10px;
    .ant-collapse-content-box {
      // background-color: $dirty-white;
      padding: 0px;
      .ant-card {
        background-color: $transparent;
      }
    }
  }
  .ant-collapse > .ant-collapse-item:last-child,
  .ant-collapse > .ant-collapse-item:last-child > .ant-collapse-header {
    border-radius: 10px 10px 0px 0px;
    border: 0px;
  }
  .ant-collapse-item:last-child > .ant-collapse-content {
    border-radius: 0px 0px 10px 10px;
  }
  .ant-table-tbody > tr:last-child > td {
    border: 0;
    border-radius: 0px 0px 10px 10px;
  }
  .ant-collapse-item-active {
    .ant-collapse-header {
      font-weight: 400;
      // background: $light-gray-color;
    }
  }

  .ant-collapse-item-active:last-child {
    .ant-collapse-header {
      border-radius: 10px 10px 0px 0px;
    }
  }

  .ant-collapse-item-active:first-child {
    .ant-collapse-header {
      border-radius: 10px 10px 0px 0px;
    }
  }

  .ant-collapse-extra {
    color: $blue-color;
  }
  .ant-pagination-item-active {
    background-color: $blue-light-color;
    border: $blue-border;
    a {
      color: $dirty-white;
    }
  }
}

// RightPart
.summary-table {
  width: 80%;
  margin: 10px auto 0;
  // border: $dotted-border;
  th,
  td {
    text-transform: capitalize;
  }
}

.ant-btn {
  &.disabled {
    color: $primary-grey;
    background-color: $light-gray-color;
    border-color: $primary-grey;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    &:hover {
      color: $dirty-white !important;
      background-color: $light-gray-color !important;
    }
  }
}

.ant-message-notice-content {
  background-color: $white-color;
  padding: 3px;
  border-radius: 2px;
}

.ant-message-custom-content {
  padding: 5px;
  border-radius: 5px;
  &.ant-message-success {
    background-color: $green-light-bg;
    border: $green-dotted-border;
  }
  &.ant-message-error {
    background-color: $red-light-bg;
    border: $red-dotted-border;
  }
}
.ant-table-tbody {
  tr {
    td {
      padding: 8px 16px;
    }
  }
}

.ant-card {
  font-size: 1em;
}
.ant-drawer-header {
  left: -1px;
}
.ant-modal-body {
  max-height: 565px;
  .location {
    max-height: 565px;
    .table-wrapper {
      height: 465px;
      overflow: auto;
    }
  }
}

.ant-modal-wrap {
  z-index: 1009998 !important;
  background-color: $soft-black;

  .ant-modal-confirm-btns,
  .ant-modal-footer {
    .ant-btn {
      width: 7em;
      &.ant-btn-primary {
        background-color: $primary-blue;
      }
      &:hover {
        background-color: $primary-green;
        border-color: $green-color !important;
      }
    }
  }
}
.anticon-setting {
  font-size: 1.4em !important;
  color: $light-green-color;
}

.ant-badge-count {
  left: -15px;
  right: auto;
  top: 5px;
}

.created-at {
  text-align: right;
  font-size: 1em;
  font-style: italic;
  padding-top: 10px;
}

.ant-layout-content {
  margin-bottom: 73px;
}
.ant-message {
  z-index: 78969999;
}

.ant-switch {
  background-color: $primary-blue;
  &.ant-switch-checked {
    background-color: $primary-green;
  }
}

.wrapper-modal {
  & > div {
    width: 48%;
    padding: 2px 1%;
    float: left;
  }
}

.ant-modal-confirm-content {
  // margin-left: 2px !important;
  margin-top: 10px !important;
}

.theme-light {
  .ant-collapse-content {
    background-color: $dirty-white;
    .ant-card {
      background-color: $white-color;
    }
  }
  .ant-drawer-content {
    background-color: $dirty-white;
  }
}

.ant-card-head {
  border: 0;
}

.ant-btn-link:hover {
  border-left: 0 !important;
}

.ant-table-cell:last-child {
  text-align: right;
}

.ant-switch-handle {
  top: 2px;
  left: 2px;
}

.ant-message-notice-content {
  .anticon {
    top: -5px;
    position: relative;
  }
}
