@import "../../../scss/palette";

.wrapper-checkbox {
  padding: 0 10px;
  .ant-checkbox {
    .ant-checkbox-inner {
      border: $blue-border;
    }
  }
  .ant-checkbox-checked {
    .ant-checkbox-inner {
      background-color: $white-color;
    }
    .ant-checkbox-inner::after {
      border: $checkbox-inner;
      border-top: 0;
      border-left: 0;
      transform: rotate(45deg) scale(1.2) translate(-50%, -50%);
    }
  }
}
