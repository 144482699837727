@import "../../scss/palette";
@import "../../scss/antdesign";
.LeftPart {
  .topBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .expand_all {
    background: rgb(246, 248, 244);
    background: -moz-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(246, 248, 244, 0) 100%
    );
    background: -webkit-linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(246, 248, 244, 0) 100%
    );
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(255, 255, 255, 1) 80%,
      rgba(246, 248, 244, 0) 100%
    );
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffffff",endColorstr="#ffffff",GradientType=1);
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    position: sticky;
    top: 64px;
    z-index: 22;
    padding: 0 2px;
    height: 50px;
    .divider {
      background-color: $orange !important;
      width: 1px;
      height: 20px;
      border: 0;
    }
    div,
    .ant-btn,
    button {
      display: inline-block;
      width: auto;
      color: $orange !important;
      &:hover {
        border: none !important;
        color: $orange !important;
      }
    }
    .ant-btn {
      display: flex;
      align-items: center;
      .anticon {
        margin-right: 10px;
      }
    }
  }

  .ant-slider-mark {
    span: {
      color: $primary-grey;
    }
    span:first-child {
      left: 15px !important;
      right: auto;
    }
    span:last-child {
      left: auto !important;
      right: 0px;
      transform: translateX(0%) !important;
    }
  }

  .show-part {
    opacity: 0.3;
    &.ant-switch-checked {
      opacity: 1;
    }
  }

  .Amortizationperiod,
  .MortgageRate,
  .Maintenanceandrenovation,
  .Annualheatingcosts,
  .Condofeeorothermonthlyexpenses,
  .Yearlyrentincrease,
  .Propertyrateofgrowth,
  .Costsofbuyingahome,
  .Costsofsellingahome {
    .tlt {
      display: none !important;
    }
  }
}
