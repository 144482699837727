@import "../../scss/palette";

.payment-table {
  .dataTable {
    border-collapse: separate;
    border-spacing: 0;
    overflow: hidden;

    th {
      background-color: $blue-light-bg;
      vertical-align: middle;
    }
    tr {
      &:hover {
        background-color: $blue-color-transparent;
      }

      td {
        text-align: center !important;
        border-bottom: none;
        border-left: none;
      }
    }
  }

  .dataTables_length,
  .dataTables_info {
    padding-left: 2em;
  }

  .pagination {
    margin-bottom: 0;
    padding-right: 2em;
  }
}
