@import "../../scss/palette";
.Payments {
  .summary-card {
    border-collapse: separate;
    border-radius: 10px;
    border-spacing: 0;
    margin: 0 auto;
    border: $primary-border;
    overflow: hidden;
    max-width: 90%;
    > div {
      border-bottom: $primary-border;
      padding: 10px;

      &.without-border {
        border-bottom: none;
      }
      &:nth-child(2n + 1) {
        background-color: $blue-soft-bg;
      }
    }
  }
  .wrapper {
    border-collapse: separate;
    border-radius: 10px;
    border-spacing: 0;
    margin: 5px;
    border: $primary-border;
    overflow: hidden;
    .switch-view-wrapper {
      border-bottom: $primary-border;
      background-color: $blue-soft-bg;
    }
  }
  .wrapper-list {
    border: none;
  }
  p {
    margin-bottom: 0em;
  }
  .txt-right {
    div:first-child {
      text-align: right;
      padding-right: 10px;
    }
  }

  .payments {
    colgroup {
      display: none;
    }
  }
  .btn-pdf {
    text-align: center;
    padding-top: 10px;
  }
  .summary-card {
    width: 450px;
    margin-top: 20px;
  }
}

@media only screen and (max-width: 575px) {
  .Payments {
    .summary-card {
      width: 100% !important;
    }
  }
}
