@import "../../scss/palette";
.wrapper-list {
  max-height: 40em;
  overflow-y: scroll;
  border: $primary-border;
  margin-bottom: 30px;
  position: relative;

  .date-view-wrapper {
    position: sticky;
    top: 0px;
    z-index: 987;
    text-align: right;
    left: 0;
    right: 0;
    border-bottom: $primary-border;
    height: 3em;
    line-height: 2.2em;
    background-color: $dirty-white;
    margin: 5px;
    > div,
    button {
      display: inline-block;
      margin: 5px;
    }
  }

  .list-cards {
    .ant-card-contain-grid {
      display: table;
      height: 100%;
      width: 100%;
      .ant-card-body {
        height: 100%;
        display: table-row;
        .ant-card-grid {
          height: 100%;
          display: inline-block;
        }
      }
    }
  }

  .ant-card {
    height: 10px !important;
    padding: 2px;
    .ant-card-body {
      display: table-row;
      height: 100%;
    }

    &:nth-child(even) {
      background-color: $white-color2;
    }
    &:hover {
      background-color: $dirty-white;
    }
    .ant-card-head-title {
      padding: 6px;
    }
  }
}
