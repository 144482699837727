@import "../../scss/palette";

.Comparison {
  .wrapper-btn {
    padding: 10px;
    text-align: center;
  }

  &.default-comparison {
    .wrapper-description {
      padding: 20px 0 10px 0;
    }
  }
  .ant-input-number-handler-wrap {
    display: none !important;
  }
}

.Mortgages {
  max-width: 800px;
  margin: 0 auto;
  .center-position {
    text-align: center;
    padding: 5px 0;
  }
  table {
    border: $gray-border !important;
    th {
      text-align: center !important;
    }
    .ant-table-row {
      td {
        text-align: center !important;
        &:nth-child(1) {
          border-right: $grey-border;
        }

        &.description {
          font-weight: 600;
          text-transform: uppercase;
          border-top: $primary-border;
          padding: 0em !important;
        }
      }

      &.tr-color-first {
        td {
          &:nth-child(1) {
            background-color: $alert-color2;
          }
          &:nth-child(2) {
            background-color: $success-color2;
          }
        }
      }
      &.tr-color-second {
        td {
          &:nth-child(1) {
            background-color: $success-color2;
          }
          &:nth-child(2) {
            background-color: $alert-color2;
          }
        }
      }
    }
  }

  .m-table {
    td,
    th {
      &:nth-child(1) {
        display: none;
      }
    }

    .description {
      font-weight: 600;
      text-transform: uppercase;
    }
    tbody {
      tr {
        &:nth-child(3) {
          td {
            border-bottom: $gray-border;
          }
        }
        &:nth-child(2),
        &:nth-child(3),
        &:nth-child(4) {
          td:not(:first-child) {
            &:after {
              display: block;
              margin: 0 auto;
              left: 0;
              bottom: 0;
              left: 0;
              border-radius: 50%;
              font-weight: 700;
              color: $red-color;
            }
          }
        }
      }
    }
  }

  .trick-input {
    padding: 0px 0px 15px 0px;
    min-width: 50%;
    width: auto;
    float: none !important;
    border: none !important;
    -webkit-box-shadow: none;
    box-shadow: none;
    .hidden {
      display: none;
    }
    .ant-input-group-wrapper {
      z-index: 10;
      position: absolute;
      left: 15px;
      width: calc(100% - 32px);
      border: $transparent-border;
      background-color: $transparent;
      padding: 0;
      text-align: center;

      .ant-input-group-addon {
        width: 40px;
        padding: 0 5px;
        height: 2.5em;
      }
      .ant-input {
        height: 2.5em;
      }
    }
  }
  .img-flag {
    width: 30px;
    padding-right: 10px;
  }
}
.right-border {
  border-right: $gray-border !important;
}
.bottom-border {
  border-bottom: $gray-border !important;
}
