@import "../../scss/palette";
@import "../../scss/antdesign";

.ltt-modal,
.all-regions {
  ul {
    list-style-image: url(/images/icon_yes.png);
    padding-inline-start: 25px;
  }
}

.all-regions {
  padding: 10px;
}
