@import "../../scss/palette";

.chart-rechart,
.chart-pie {
  padding-bottom: 2em;
  margin-bottom: 1em;
  border-bottom: $dotted-border;
  .custom-tooltip {
    background-color: $white-light-bg;
    color: $black-color;
    border: $gray-border;
    border-radius: 3px;
    padding: 3px;
    text-align: left;
  }
  .custom-legend {
    padding: 5px 0;
    text-align: center;
    font-weight: 500;
    ul {
      li {
        text-align: left;
      }
    }
  }
}

.chart-pie,
.chart-rechart {
  .recharts-wrapper {
    margin: 0 auto;
  }
}
