@import "../../scss/palette";
@import "../../scss/resolution";
.HeaderMenu {
  display: flex;
  justify-content: space-between;
  position: fixed;
  text-align: center;
  z-index: 111750 !important;
  width: 100%;
  background: #000;
  /*
  background-color: $menu-bg;
  background: -moz-linear-gradient(
    90deg,
    rgba(91, 91, 91, 1) 0%,
    rgba(118, 118, 118, 1) 100%
  );
  background: -webkit-linear-gradient(
    90deg,
    rgba(91, 91, 91, 1) 0%,
    rgba(118, 118, 118, 1) 100%
  );
  background: linear-gradient(
    90deg,
    rgba(91, 91, 91, 1) 0%,
    rgba(118, 118, 118, 1) 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#5b5b5b",endColorstr="#767676",GradientType=1); */
  left: 0;
  top: 0;
  // border-bottom: $primary-border;

  .wrapper-menu {
    width: 80%;
    text-align: right;

    .wrapper-nav {
      position: relative;
      display: inline-block;
      vertical-align: bottom;

      a {
        width: 100%;
        display: flex;
        align-items: baseline;
        .navText {
          display: block;
        }
      }

      .nav-link {
        font-size: 1.2em;
        text-transform: capitalize;
        color: $light-gray-color;
        padding: 0 1em;
        height: 100%;
        align-items: center;
        display: flex;
        justify-content: center;
        flex-direction: row;
        margin: 0;
        border-bottom: 0px !important;
        path {
          &:nth-child(1) {
            fill: $white-color;
          }
          &:nth-child(2) {
            fill: $black-color;
          }
        }
        &.selectedLink,
        &:hover {
          color: $blue-light-color;
          path {
            &:nth-child(1) {
              fill: $blue-light-color;
            }
          }
          a {
            color: $blue-light-color;
          }
        }
        &.nav-link-logo {
          height: 100%;
          width: 100%;
        }
      }
      .anticon {
        font-size: 2em;
        margin: 5px;
      }
    }
  }

  input[type="button"] {
    &:hover {
      cursor: pointer;
    }
    &:focus {
      border: none;
    }
  }
  .logo {
    width: 202px;
    height: 130px;
    background: url("/mortgagio.png") no-repeat;
    margin-top: -65px;
    margin-left: 1px;
    float: left;
  }
  .logo {
    a {
      width: 100%;
      height: 100%;
    }
  }
}

@media only screen and (max-width: 408px) {
  .HeaderMenu {
    height: 64px;
    font-size: 0.8em;

    .wrapper-menu {
      justify-content: space-between;
      display: flex;
      width: 100%;
      text-align: center;
      margin: 0;
      position: fixed;
      height: 64px;
      bottom: 0px;
      background-color: $dark-gray-color !important;
      line-height: 32px;
      .ant-menu-submenu {
        margin-top: 0px !important;
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .HeaderMenu {
    height: 64px;
    font-size: 0.8em;

    .wrapper-menu {
      justify-content: space-between;
      display: flex;
      width: 100%;
      text-align: center;
      margin: 0;
      position: fixed;
      height: 64px;
      bottom: 0px;
      background-color: $dark-gray-color !important;
      line-height: 32px;
      .ant-menu-submenu {
        margin-top: 0px !important;
      }
      .wrapper-nav {
        .nav-link {
          flex-direction: column;
          font-size: 1.2em !important;
        }
      }
      .only-top {
        width: auto !important;
        position: fixed;
        right: 0;
        top: 0;
        height: 64px;
        text-align: right;
        display: flex;
        justify-content: flex-end;
        padding: 0px;
        .nav-link {
          display: flex !important;
          justify-content: center;
          align-items: center;
          border-bottom: 0px !important;
          padding: 0px;
          margin-right: 5px;
        }
      }

      .wrapper-nav {
        width: 100%;
        a {
          width: 100%;
          justify-content: center;
          align-items: center;
        }
      }
    }
  }
}
