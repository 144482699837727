@import "../../scss/palette";
.Mortgage {
  text-align: center;
  .ant-btn {
    padding: 5px 1px !important;
    &:hover {
      border-left: unset !important;
    }
  }
}

.textarea-wrapper {
  margin-bottom: 10px;
}
.ant-modal {
  h3 {
    text-align: center;
  }
}
.selected {
  color: $violet;
  font-weight: 400;
}

.comment {
  color: $gray-color;
  font-size: 0.9em;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 30ch;
}
.ant-message-notice-content {
  span,
  .anticon {
    font-size: 1.2em;
  }
}

.ant-btn-link {
  background: transparent !important;
  border: none !important;
  color: $blue-color !important;
  &:hover {
    color: $blue-light-border !important;
    border-left: $blue-light-border !important;
  }
}

.table {
  td {
    padding-top: 0.4rem !important;
    padding-bottom: 0.4rem !important;
    font-size: 0.9em;
  }
}

.mortgage-modal {
  h3 {
    font-weight: 400;
  }
  .wrapper-table-modal {
    .table {
      border: $primary-border;
      tr {
        &:nth-child(2n) {
          background: $light-gray-color;
        }
        &:hover {
          background: $gray-color;
        }
      }
      th,
      td {
        border-top: $primary-border;
      }
    }
  }
  .ant-divider-inner-text {
    font-weight: 400;
    .anticon {
      color: $blue-color;
    }
  }
  .ant-divider {
    &:before,
    &:after {
      border-top: $dotted-border;
    }
  }
  .div-tip {
    padding: 0 10px;
    min-height: 1em;
    pre {
      font-family: "Roboto", sans-serif;
      font-size: 1em !important;
    }
  }
}
