@import "../../scss/palette";

.wrapper-table-modal {
  div {
    padding: 0 !important;
  }
  .row {
    margin-right: 0px;
    margin-left: 0px;
    td {
      font-size: 1.1em;
      padding: 7px 2px !important;
    }
    .justify-content-between,
    .mdb-dataTable-head {
      display: none !important;
    }
  }
}

.modal-data-table {
  width: 100%;
  th {
    background-color: $blue-light-bg;
    vertical-align: middle;
  }
  tr {
    border-bottom: $gray-border;
    &:hover {
      background-color: $blue-color-transparent;
    }

    td {
      font-size: 1.1em;
      padding: 7px 2px !important;
      text-align: left !important;
    }
  }
}
