@import "../../scss/palette";
@import "../../scss/resolution";
.LeftPart {
  .ant-card {
    margin: 0 auto;
    .ant-card-body {
      padding: 0px;
      width: 100%;
      & > div {
        -webkit-box-shadow: none;
        box-shadow: none;
        &.tlt {
          width: 100% !important;
        }
        &:nth-child(even) {
          width: 50%;
        }
        &:nth-child(odd) {
          width: 50%;
        }
      }

      .set-value {
        display: inline-block;
        &.set-value-plus {
          float: right;
        }
      }
      .disabled {
        color: $gray-color !important;
      }
    }

    .be-return_investment {
      .ant-input-number-handler-wrap {
        display: block !important;
      }
    }

    .ant-input-number-handler-wrap {
      display: none !important;
    }
    .mortgage_rate_alt {
      width: 100% !important;
    }
    .list-images {
      padding: 0 10px;
      a {
        display: inline-block;
        font-weight: 400;
        span {
          padding: 0 10px;
        }
        img {
          width: 70px;
        }
      }
    }
  }
}

.ant-input-number:focus,
.ant-input-number-focused,
.textarea-tip:focus {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.textarea-tip:focus {
  border: $dotted-border;
}

.ant-input-number:focus,
.ant-input-number-focused {
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.theme-light {
  .LeftPart {
    .ant-card-bordered {
      border: 0;
      // border: 1px solid $light-gray-color;
    }
  }
}

.CardComponent__root:hover {
  background: $card-hover-color;
}

.approximately {
  text-align: right;
  font-size: 1em;
  font-weight: 400;
  width: 100% !important;
}

.mobile-input-min-max {
  display: flex;
  justify-content: center;
  justify-items: center;
  align-items: baseline;
  margin: 25px 0;
}
