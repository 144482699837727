@import "./resolution";
@import "./palette";

.Mortgages {
  .trick-input {
    .input-elements-wrapper {
      text-align: left !important;
    }
  }
}
.trick-input {
  position: relative;
  padding: 0px;
  padding: 0 5px 15px 5px;

  &.is-money {
    input {
      text-align: left;
    }
  }

  .hidden {
    display: none;
  }

  /********************************start*/
  .input-elements-wrapper {
    width: 210px;
    margin: 0 auto;
    position: relative;

    .span-holder {
      display: flex;
      justify-content: center;
      justify-items: center;
      align-items: baseline;
    }

    .span-value {
      font-size: 1.2em;
      font-weight: 800;
      // padding: 0 0 0 1em;
      // border-bottom: $primary-border;
      .ant-btn {
        font-size: 1.4em;
        padding: 0 0.2em !important;
      }
    }

    .be-input {
      border: none;
      border-radius: 5px;
      position: relative;
      width: 150px;
      background: transparent;
      z-index: 11;
      &:focus,
      &:active,
      &:hover {
        outline: 0 !important;
        outline-offset: 0 !important;
      }
      input {
        border: $primary-border !important;
        line-height: 48px;
        color: $text-input;
        font-size: 1.1em;
        text-align: right;

        &:focus,
        &:active,
        &:hover {
          outline: 0 !important;
          outline-offset: 0 !important;
        }
      }
      .ant-input-number-handler-wrap {
        display: block;
        opacity: 1;
        right: -12px;
        border: $primary-border;
        .ant-input-number-handler {
          overflow: visible;
        }
      }
      &.left-25 {
        left: 50px;
      }
      .ant-input-number-input {
        height: 34px;
        padding: 0 17px 0 0;
        width: 100%;
        &:focus {
          background: $blue-light-bg;
        }
      }
    }

    .pre-suf {
      position: absolute;
      top: 0px;
      height: 34px;
      border: $primary-border;
      width: 50px;
      text-align: center;
      font-size: 1.1em;
      font-weight: 300;
      padding: 0;
      background-color: $light-gray-color;
      line-height: 2.1em;
      &.prefix {
        left: 0px;
        border-right: none;
      }
      &.suffix {
        left: 150px;
        border-left: none;
      }
    }
  }
  /********************************end*/
}

@media only screen and (max-width: 991px) {
  /* card */

  .container {
    max-width: 760px;
    .ant-card {
      .ant-card-body {
        width: 300px !important;
      }
    }

    .ant-input-number {
      font-size: 0.9em !important;
    }

    .ant-input-wrapper {
      &.ant-input-group {
        .ant-input {
          font-size: 0.9em !important;
        }
        .ant-input-group-addon {
          width: 39px !important;
          font-size: 0.9em !important;
        }
      }
    }
  }
  /* card end*/
}

@media only screen and (max-width: 767px) {
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
    .ant-card {
      .ant-card-body {
        width: auto !important;
      }
    }
    .trick-input {
      .ant-input-group-wrapper {
        width: 202px !important;
      }
    }

    .ant-card-grid {
      padding: 1px 10px;
    }
    .ant-card {
      .ant-card-body {
        .ant-input-number {
          top: 0 !important;
        }
      }

      .CardComponent__root {
        .ant-card {
          .ant-card-body {
            width: 400px !important;
            .ant-input-group-wrapper {
              width: 300px !important;
            }
            .ant-input-number {
              width: 222px !important;
              .ant-input-number-input {
                width: 252px !important;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 575px) {
  .CardComponent__root {
    margin-bottom: 10px;
    .ant-card {
      border: none;
    }
  }
  .ant-input-group-wrapper {
    top: 4px;
  }

  .trick-input {
    .input-elements-wrapper {
      .pre-suf {
        line-height: 2.7em;
      }
    }
  }
}

@media only screen and (max-width: 480px) {
  .trick-input {
    .input-elements-wrapper {
      .pre-suf {
        line-height: 2.3em;
      }
    }
  }
  .Mortgages {
    .trick-input {
      .input-elements-wrapper {
        width: 180px;
        .be-input {
          width: 130px;
        }
        .pre-suf {
          line-height: 2.7em;
          &.suffix {
            left: 140px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 380px) {
  .Mortgages {
    .trick-input {
      .input-elements-wrapper {
        width: 150px;
        .be-input {
          width: 100px;
        }
        .pre-suf {
          &.suffix {
            left: 110px;
          }
        }
      }
    }
  }
}
