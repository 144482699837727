@import "../../scss/palette";

.Post {
  .backBtn {
    color: #00000099 !important;
    font-size: 8px;
  }
  .markdown-text {
    table {
      width: 100%;
      margin: 1em 0;
      td {
        border: $primary-border;
        padding: 0.3em;
      }
    }
    h1,
    h2,
    h3 {
      margin: 0.7em 0;
      text-indent: 1.5em;
    }
    p {
      font-size: 1.2em;
      margin-bottom: 0.7em;
      text-indent: 1.5em;
      &::first-letter {
        font-size: 1.3em;
      }
    }

    img {
      margin: 0 auto;
      max-width: 75%;
    }
  }

  .front-image {
    text-align: center;
    padding: 1em;
    img {
      width: 100%;
    }
  }

  button {
    span {
      font-size: 2em;
      position: relative;
      top: -0.2em;
    }
  }

  section {
    font-size: 2em;
    font-weight: bold;
    transition: all 200ms;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
    background-position: 50% 50%;
    display: inline-block;
    place-items: center;
    z-index: 1;
    cursor: pointer;
    position: relative;
    padding: 5px 10px;
  }
  /*Waves*/
  .waves {
    color: transparent;
    -webkit-text-stroke: 1px $primary-blue;
    position: relative;
  }
  .waves:after {
    content: attr(data-word);
    position: absolute;
    top: 0;
    left: 0;
    color: $primary-blue;
    animation: waves 2s ease-in-out infinite forwards;
  }
  @keyframes waves {
    0%,
    100% {
      clip-path: polygon(
        0 66%,
        11% 59%,
        18% 51%,
        26% 46%,
        35% 41%,
        48% 44%,
        55% 54%,
        63% 63%,
        76% 60%,
        82% 50%,
        92% 48%,
        100% 53%,
        100% 100%,
        0% 100%
      );
    }
    50% {
      clip-path: polygon(
        0 66%,
        8% 74%,
        17% 77%,
        28% 70%,
        35% 57%,
        48% 44%,
        56% 39%,
        69% 41%,
        75% 47%,
        84% 60%,
        92% 61%,
        100% 53%,
        100% 100%,
        0% 100%
      );
    }
  }

  .signature {
    text-align: left;
    padding-left: 3em;
  }
}
