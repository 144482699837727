@import "../../../scss/palette";
.Mortgage {
  .new-row {
    div {
      color: $red-color !important;
    }
  }
}
.mortgage-modal {
  h4 {
    border-bottom: $grey-border;
    padding: 5px 0;
    font-size: 1.1em;
  }
}

.modal-card {
  .ant-card-body {
    padding: 5px;
    p {
      margin-bottom: 0.3em !important;
    }
    textarea {
      width: 100%;
    }
  }
}

.modal-second-btn {
  width: 30px;
  height: 30px;
  padding: 0 0 0 20px;
  svg {
    width: 30px;
    height: 30px;
  }
}
p {
  margin-bottom: 0.5em;
}
div[contenteditable="true"] {
  font-family: sans-serif;
  margin: 2px 2px 12px 2px;
  min-height: 50px;
  border: 1px dashed #aaa;
  padding: 5px;
}
