@import "../../scss/palette";

.post-title {
  padding-bottom: 1em;
  .anticon-folder-open {
    top: -0.2em;
    position: relative;
    padding: 0 0.2em;
  }
}
