@import "../../scss/palette";

.Blog {
  padding: 0.8em;

  .list-posts {
    .row {
      margin-right: 0px;
      margin-left: 0px;
    }
    .dataTable {
      border-collapse: separate;
      border-spacing: 0 1em;
      border: none;
      thead {
        display: none;
      }
      tr {
        &:hover {
          cursor: pointer;
        }
        td {
          border: none;
          &:nth-child(1) {
            width: 30%;
          }
        }
      }
    }
    .blog-img {
      max-width: 100%;
    }
  }
  h1 {
    font-weight: 900 !important;
    font-size: 2.2em;
    &.title-h1 {
      text-align: center;
    }
  }
  h2 {
    font-weight: 400 !important;
    font-size: 1.7em;
  }
}
