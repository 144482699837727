@import "../../scss/palette";

.author-mini {
  display: inline;
  padding: 0 20px;
  margin: 20px 0;
  img {
    width: 40px;
    border-radius: 50%;
    top: -8px;
    position: relative;
  }
}
