@import "../../scss/palette";
.social-buttons {
  position: fixed;
  width: 60px;
  height: 130px;
  background-color: $dirty-white;
  z-index: 8888;
  overflow: hidden;
  left: 0;
  top: calc(50% - 150px);
  border: $primary-border;
  border-left: none;
  border-radius: 0 10px 10px 0;
  padding-top: 7px;

  .one-button {
    text-align: center;
    padding: 7px 0;
  }
}
