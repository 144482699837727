@import "../index";
@import "../scss/palette";

.App {
  margin: 64px auto 0px;
  min-height: calc(100vh - 64px);
  position: relative;
  .site-layout {
    background-color: $white-color;
  }
  .ant-back-top {
    right: 50px;
    svg {
      height: 40px;
      width: 40px;
    }
    .up {
      width: 40px;
      height: 40px;
      line-height: 2em;
      padding: 4px 1px;
      background-color: $primary-blue !important;
      &:hover {
        background-color: $primary-green !important;
        border-color: $green-color !important;
      }
      .anticon-arrow-up {
        font-size: 1.4em;
      }
    }
  }

  > .rem {
    position: fixed !important;
    top: 67px;
    right: 5px;
    z-index: 999;
    div,
    button {
      display: block;
    }
  }
  .ant-btn {
    font-weight: 400;
    position: relative;
    padding: 5px 15px;
    height: auto;
  }
  .ant-btn[disabled] {
    color: $gray-color !important;
  }
  input {
    border-radius: 0;
  }

  &.theme-light {
    background-color: $dirty-white;
    .wrapper-div {
      background-color: transparent;
    }

    .ant-btn {
      background: $gray-color;
      border: $gray-border;
      color: $dark-gray-color;
      &:hover {
        color: $white-color;
        background-color: $blue-light-color;
        border: $blue-border;
      }
    }
  }

  .ant-card-head-wrapper {
    padding-left: 10px;
  }
  @media only screen and (max-width: 767px) {
    .ant-card-head-wrapper {
      padding-left: 0px;
    }
  }
  li::marker {
    color: $primary-blue;
    font-size: 1.3em;
  }
}

.spinner-bg {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 99999;
  background-color: $white-color;
  text-align: center;
  left: 0;
  top: 64px;
  > span {
    top: calc(50% - 150px);
    position: absolute;
    left: calc(50% - 75px);
    span {
      &:nth-child(1) {
        border: 15px solid $primary-blue;
        opacity: 1;
      }
      &:nth-child(2) {
        border: 15px solid $primary-green;
        opacity: 1;
      }
    }
  }
}

.wrapper-table-modal {
  .table {
    border: $primary-border;
    tr {
      &:nth-child(2n) {
        background: $light-gray-color;
      }
      &:hover {
        background: $gray-color;
      }
    }
    th,
    td {
      border-top: $primary-border;
    }
  }
}

.table {
  thead {
    tr {
      background-color: $blue-color-transparent;
    }
  }
  tbody {
    tr:hover {
      background-color: #f8f8f8;
      transition: background 0.3s;
    }
  }
}
