@import "./palette";
.ant-slider {
  height: 7px;
  border: $blue-border;
  box-shadow: none;
  margin-top: 12px;
  margin-bottom: 36px;
  padding: 0px;
  border-radius: 2px;
  background-color: $blue-color-transparent;
  &:hover,
  &:active {
    .ant-slider-track {
      background-color: $blue-light-border;
    }
    .ant-slider-handle {
      border: $blue-light-border !important;
      &:after {
        background-image: $bg-webkit-hover;
        background-image: $bg-moz-linear-hover;
        background-image: $bg-webkit-linear-hover;
        background-image: $bg-linear-hover;
      }
    }
  }

  .ant-slider-mark {
    top: 20px;
  }
  .ant-slider-track {
    background: $blue-color;
    top: 0px;
    height: 7px;
  }
  .ant-slider-handle {
    top: -5px;
    touch-action: pan-y;
    background: $white-color;
    border: $blue-border;
    cursor: pointer;
    display: inline-block;
    width: 30px;
    height: 30px;
    position: absolute;
    &:after {
      content: "";
      display: block;
      width: 14px;
      height: 14px;
      margin: auto;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-image: $bg-webkit;
      background-image: $bg-moz-linear;
      background-image: $bg-webkit-linear;
      background-image: $bg-linear;
      -moz-border-radius: 50%;
      -webkit-border-radius: 50%;
      border-radius: 50%;
    }
  }
  .ant-slider-rail {
    height: 0px;
    background-color: transparent;
    top: 0px;
    &.hover {
      background-color: transparent !important;
    }
  }
  .ant-slider-dot {
    border-color: transparent;
    background-color: transparent;
    display: none;
  }
}
