@import "../../scss/palette";
@import "../../scss/antdesign";

.RightPart-body {
  margin-bottom: 10px;
}
.RightPart {
  position: sticky;
  top: 75px;
  .text {
    text-align: center;
    text-transform: capitalize;
    padding: 10px 5px;
    margin-bottom: 0em;
    font-weight: 300 !important;
  }
  .text2 {
    text-align: center;
    padding: 20px 5px;
  }
  .reset-btn {
    text-align: right;
    width: 80%;
    margin: 0 auto;
    padding: 5px 0px;
  }
  .wrapper-buttons {
    text-align: center;
    button {
      margin: 5px;
      padding: 10px 5px;
    }
  }

  .summary-advice {
    padding: 5px;
    background-color: $dirty-white;
    border-radius: 10px 10px 0px 0px;
  }
}

.theme-light {
  .RightPart {
    .ant-collapse {
      background-color: $dirty-white;
    }
  }
}

.wrapper-btns {
  display: flex !important;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: 10px;
  .ant-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-right: 10px !important;
    .anticon {
      margin-right: 8px !important;
      margin-bottom: 2px;
    }
    span {
      margin-left: 2px !important;
    }
  }
}
