@import "../../scss/palette";
.ComparisonTable {
  .list-mortgages {
    width: 100%;
    th,
    td {
      padding: 8px;
    }
  }
  .ant-typography {
    padding: 6px 5px 10px;
    display: block;
  }

  .ant-table-content {
    table {
      table-layout: fixed !important;
      tr {
        th,
        td {
          overflow: hidden;
          &:nth-child(1) {
            width: 10%;
          }
          &:nth-child(2) {
            width: 45%;
          }
          &:nth-child(3) {
            width: 15%;
          }
          &:nth-child(4) {
            width: 15%;
          }
          &:nth-child(5) {
            width: 15%;
          }
        }
      }
    }
    .ant-table-cell {
      text-align: left;
    }
  }

  .bold-400 {
    font-weight: 400;
  }

  .ant-empty-image {
    height: auto;
    margin-bottom: 0;
    padding-bottom: 1em;

    svg {
      margin: 1em;
    }
  }
}
