@import "./scss/palette";
@import "./scss/antdesign";

.div-wrapper {
  padding: 0px 10px;
}

html {
  min-height: 100vh;
  height: auto;

  background-size: auto;
  background-size: cover;

  body {
    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
      outline: 0;
    }

    h3 {
      font-size: 1.4em;
    }
    h4 {
      font-size: 1.3em;
    }

    position: relative;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-color: transparent;
    min-height: calc(100vh - 64px);
    height: auto;
    font-size: 14px;

    #root {
      //  max-width: 1199px;
      margin-right: auto;
      margin-left: auto;
      padding-left: 0px;
      padding-right: 0px;
      min-height: calc(100vh - 64px);
      .mobile {
        display: none;
      }
      .watch-me {
        background-color: $orange;
        &.selectedLink {
          background-color: $gold-color;
        }
        a {
          color: $white-color;
        }
      }

      table {
        th {
          font-size: 1em;
          font-weight: 400;
          text-align: center;
        }
        td {
          font-size: 1em;
        }
      }
    }

    .mortgage-rate {
      .rates {
        font-size: 1.2em;
        .rates-title {
          margin: 0 40px 0 10px;
          text-align: center;
          font-weight: 400;
        }
      }
      .anticon-info-circle {
        display: none;
      }
    }

    .switch-view-wrapper {
      text-align: right;
      height: 3em;
      line-height: 1.8em;
      > div,
      button {
        display: inline-block;
        margin: 5px 5px;
      }
    }

    .author-details {
      max-width: 450px;
      border-radius: 10px;
      width: 96%;
      border-radius: 10px;
      .ant-popover-title {
        text-align: center;
        padding: 0.7em 0;
        border-radius: 10px;
      }
      .ant-popover-inner {
        border-radius: 10px;
      }
      .ant-popover-inner-content {
        .author-img {
          img {
            width: 100%;
            border-radius: 10px;
          }
        }
        .author-dtl {
          padding: 0.7em 0;
          h5 {
            font-size: 1.2em;
            font-weight: 500;
          }
        }
      }
    }

    .folder-open {
      button {
        span {
          color: $blue-light-color;
        }
        font-size: 1.2em;
        svg {
          font-size: 1.2em;
          position: relative;
          top: -0.2em;
        }
      }
      &:hover {
        span {
          color: $red-color !important;
          transition: 0.3s;
        }
        path {
          &:nth-child(4) {
            fill: $red-color;
          }
          &:nth-child(5) {
            fill: $red-color;
          }
          transition: 0.3s;
        }
        border: none !important;
      }
    }
    .several-buttons {
      button {
        font-size: 1.2em;
        svg {
          font-size: 1.2em;
          position: relative;
          top: -0.2em;
        }
      }

      button {
        width: auto;
        span {
          color: $blue-light-color;
        }
        path {
          &:nth-child(1) {
            fill: $white-color;
          }
          &:nth-child(2) {
            fill: $white-color;
          }
        }

        &:hover {
          span {
            color: $red-color !important;
            transition: 0.3s;
          }
          path {
            &:nth-child(4) {
              fill: $red-color;
            }
            &:nth-child(5) {
              fill: $red-color;
            }
            transition: 0.3s;
          }
          border: none !important;
        }
      }
    }
    .ant-tabs {
      .ant-tabs-nav-list {
        margin: 0 auto;
      }
    }
  }
  *:focus {
    outline: none;
  }
  .paginator-off {
    .ant-pagination {
      display: none !important;
    }
  }
  .create-link {
    color: $white-color !important;
    background-color: $red-soft-color !important;
    border-color: $red-soft-color !important;
    &:hover {
      background-color: $red-soft2-color !important;
      border-color: $red-soft2-color !important;
    }
  }
}
