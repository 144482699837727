@import "../../scss/palette";
.SelectRegion {
  display: flex;
  justify-content: space-between;
  height: 40px;
  .SelectRegion__label {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    text-align: right;
    width: 100%;
  }
  .SelectRegion__text {
    margin-left: 5px;
    margin-right: 5px;
  }
  .be-select {
    font-weight: 600;
    padding: 5px;
    width: 148px;
    .ant-select-selector {
      background: transparent;
      border-top: none;
      border-left: none;
      border-right: none;
      // border-bottom: $primary-border;
      border: 0px;
      border-radius: 0px;
    }
  }
}
.location {
  table {
    width: 100%;
    td.ant-table-cell,
    td.td-list-modal {
      text-align: left !important;
      cursor: pointer;
      padding: 4px 16px;
      border-bottom: $grey-border;
      &:hover {
        background-color: $light-gray-color;
        transition: background 0.3s;
      }
    }
    .ant-btn {
      margin: 20px 0 10px 0;
      font-size: 1.1em;
    }
  }
}

.location-img {
  width: 20px;
  margin-left: 10px;
  margin-right: 5px;
}
.cntry-name {
  margin-left: 5px;
  margin-right: 5px;
}

.select-country {
  height: 3em;
  display: block;
  text-align: center;
  .checkTablet {
    display: block;
  }
}
