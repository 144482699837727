@import "../../scss/palette";
.wrapper-charts {
  .chart-options {
    padding: 10px;
    & > div {
      display: inline-block;
      min-width: 20%;
      max-width: 80%;
      &.switch-view-wrapper {
        & > div {
          display: inline-block;
          margin: 5px 5px;
        }
      }
    }
    .ant-select-multiple {
      .ant-select-selector {
        border: $dotted-border;
        border-radius: 5px;
        padding: 5px;
        .ant-select-selection-item {
          background-color: $green-soft-color;
          border: $green-border;
          width: auto;
          .anticon-close {
            vertical-align: 0;
          }
        }
      }
    }
  }
}
