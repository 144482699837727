@import "../../scss/palette";
.ant-modal-body {
  padding: 10px !important;
  .ant-pagination {
    display: none !important;
  }
}

.question {
  padding: 10px 20px;
  & span {
    font-weight: 100 !important;
    padding: 0 10px;
  }
  .anticon {
    font-size: 1.2em;
    vertical-align: 0;
  }
}
