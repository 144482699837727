@import "../../scss/palette";
.DrawerComponentLeft {
  display: inline-block;
  .helper {
    background: $transparent;
    border: none;
    &:focus {
      border: $transparent;
    }
  }
  span {
    position: relative;
  }
  .ant-drawer {
    position: fixed !important;
    .ant-drawer-content-wrapper {
      .ant-drawer-body {
        padding: 5px;
        text-align: center;
        .ant-divider-inner-text {
          font-size: 0.8em;
        }
        .ant-divider-horizontal {
          color: $primary-grey;
          &:before,
          &:after {
            border-top: $dotted-border;
          }
        }
      }
    }
  }
  .helper {
    margin-right: 15px;
    background: $transparent !important;
    border: $transparent !important;
    color: $blue-light-color;
    &:hover {
      color: $blue-light-color !important;
    }
  }

  .anticon {
    font-size: 1.4em !important;
    &:hover {
      font-size: 2.1em !important;
      transition: 0.4s;
    }
    &.anticon-close:hover,
    &.anticon-save:hover {
      font-size: 1.4em !important;
    }
    &.anticon-save:hover {
      font-size: 1.4em !important;
    }
    &.anticon-profile,
    &.anticon-unordered-list {
      color: $primary-green;
    }
  }
}
.ant-table-thead {
  th {
    background-color: $dirty-black-color;
    color: $dirty-white;
    font-size: 1em;
  }
  th,
  td {
    overflow-wrap: unset !important;
  }
}
.ant-table-tbody {
  td {
    font-size: 1em;
  }
}
