@import "./palette";

@media only screen and (max-width: 1200px) {
}

@media only screen and (max-width: 991px) {
  body {
    // font-size: 12px !important;
    h3 {
      font-size: 1.1em !important;
    }
    h4 {
      font-size: $font-size-09;
    }
    .ant-btn {
      // font-size: 12px;
    }
  }
  .ant-collapse {
    // font-size: 12px !important;
  }
  .ant-input-number {
    input {
      // font-size: $font-size-09;
    }
  }
  .summary-table {
    width: 95% !important;
    td {
      padding: 6px 8px !important;
    }
  }

  .LeftPart {
    .ant-card {
      .ant-card-body {
        & > div {
          &.tlt {
            width: 100% !important;
          }
          &:nth-child(even) {
            width: 100% !important;
          }
          &:nth-child(odd) {
            width: 100% !important;
          }
        }
      }
    }
  }

  .HeaderMenu {
    .wrapper-menu {
      .wrapper-nav {
        .nav-link {
          padding: 0 0.25em !important;
          font-size: 1em;
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .div-wrapper {
    padding: 0;
  }

  .LeftPart {
    .topBlock {
      padding: 10px 5px 0 10px;
      /*  border-bottom: 1px solid;
      margin-bottom: 5px;
      border-radius: 10px; */
    }
  }

  .mainContent {
    padding-right: 5px;
    padding-left: 5px;
  }
  .container {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  .ant-menu-sub {
    li {
      line-height: 1em !important;
      .anticon {
        display: none;
      }
      .top-10 {
        position: relative;
        top: 12px;
      }
    }
  }
  .laptop {
    display: none;
  }
  .span-block {
    display: block;
  }
  .mobile {
    display: inline-block !important;
  }
  .no-mobile {
    display: none !important;
  }
  .HeaderMenu {
    // top: unset !important;
    // bottom: 0 !important;
    .ant-menu-item {
      margin-top: 0px !important;
    }
  }
  .ant-table-content {
    overflow-x: scroll;
    tr {
      th:first-child,
      td:first-child {
        position: sticky;
        left: 0px;
        z-index: 2;
      }
      th:first-child {
        padding: 0px 15px !important;
      }
      td:first-child {
        background-color: $white-color;
      }
    }
  }

  .show-mobile {
    .checkTablet {
      display: block;
    }
  }

  .checkTablet {
    display: none;
  }
  .rates {
    .checkTablet {
      display: block !important;
    }
  }

  .ant-collapse {
    border-radius: 0;
  }

  .wrapper-menu {
    .wrapper-nav {
      width: 20% !important;
      a {
        display: inline-block !important;
        padding: 0 0.3em !important;
      }
    }
  }

  .Blog {
    .list-posts {
      .row {
        &:nth-child(1) > div {
          width: 50%;
          padding: 0.2em;
          label {
            padding-bottom: 0;
          }
        }
      }
      .custom-select-sm,
      input {
        font-size: 1em;
      }
    }
  }
  .ant-back-top {
    bottom: 70px;
  }
}

@media only screen and (max-width: 575px) {
  #root {
    .wrapper-div {
      border: none !important;
    }

    .Mortgages {
      table {
        th,
        td {
          font-size: 0.8em;
        }
      }
    }
  }

  .ant-message-notice-content {
    span,
    .anticon {
      font-size: 1em;
    }
  }
  .ant-tooltip-content {
    display: none;
  }
  .ant-btn {
    padding: 5px 1px !important;
  }
  .be-select {
    width: 102px !important;
  }

  .ant-modal-body {
    padding: 16px 8px 24px !important;
  }

  .DrawerComponentLeft {
    .ant-badge-count {
      top: 0px;
    }
  }
  .ant-card-head-title {
    font-size: $font-size-09;
  }

  .location {
    .td-list-modal {
      font-size: $font-size-09;
    }
  }

  .ant-card-head {
    min-height: 1.2em;
    .ant-card-extra {
      padding: 0;
    }
  }
  .ant-back-top {
    right: 10px !important;
  }
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
  .site-layout {
    font-size: $font-size-09;

    .ant-collapse {
      .ant-collapse-item {
        .ant-collapse-header {
          font-size: $font-size-09;
        }
      }
    }

    .ant-btn {
      & > span {
        font-size: $font-size-09;
      }
    }

    .ant-typography {
      &.ant-typography-secondary {
        font-size: $font-size-09;
      }
    }

    .mobile-input-min-max {
      margin: 5px 0;
    }
  }

  .ant-table-content,
  .modal-data-table {
    tr {
      th,
      td {
        font-size: $font-size-09;
      }
    }
  }

  .ant-modal {
    top: 35px !important;
  }

  .wrapper-charts {
    .chart-options {
      & > div {
        width: 100% !important;
        max-width: 100% !important;
        .ant-select-selection-item {
          font-size: $font-size-09;
        }
      }
    }
  }

  .spinner-bg {
    > span {
      left: calc(50% - 50px);
      span {
        width: 100px;
        height: 100px;
      }
    }
  }

  .social-buttons {
    position: relative !important;
    width: 100% !important;
    height: auto !important;
    border: none !important;
    text-align: center;
    > div {
      display: inline-block;
      padding: 0 10px !important;
    }
  }
  .RightPart {
    margin-bottom: 63px;
  }
}

@media only screen and (max-width: 480px) {
  .ant-table {
    th,
    td {
      padding: 4px !important;
    }
  }
  .ant-back-top {
    z-index: 456789;
  }

  .Blog {
    .list-posts {
      .dataTable {
        tr {
          td {
            display: table-row;
            &:nth-child(1) {
              width: 100%;
            }
            .post-title {
              border-bottom: $dotted-border;
            }
          }
        }
      }
    }
  }
}
