@import "../../scss/palette";
@import "../../scss/antdesign";

.RebatePage {
  .table {
    border: $primary-border;
    max-width: 600px;
    tr {
      &:nth-child(2n + 1) {
        background: $light-gray-color;
      }
      &:hover {
        background: $gray-color;
      }
    }
    th,
    td {
      &:nth-child(2n) {
        width: 50%;
      }
      border-top: $primary-border;
    }
  }
}
