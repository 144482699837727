@import "../../scss/palette";

.rates-list {
  .link:hover {
    color: $green-color;
  }
  .user-select-item {
    border: $blue-border;
    color: $blue-color;

    span {
      font-size: 1.1em;
      position: relative;
      top: -0.1em;
    }

    &:hover {
      background-color: $blue-color-transparent;
      span {
        color: $primary-blue;
      }
    }
  }

  .rates {
    thead {
      td {
        padding: 0.1em !important;
        text-align: center;
        font-size: 0.85em;
      }
    }
    tbody {
      tr {
        height: 2.5em;
        td {
          vertical-align: middle;
          padding: 0.3em 0.1em !important;
          font-size: 0.75em;
          &:nth-child(1) {
            width: 49px;
          }
          &:nth-child(3) {
            width: 59px;
          }

          &.rate-value {
            text-align: center;
          }
        }
      }
    }
  }
  .mini-img {
    width: 40px;
  }

  .custom-rate {
    width: 4.3em;
    .ant-input-number-handler-wrap {
      display: none;
    }
    > div {
      display: inline-block;
    }
    .ant-input-number {
      border-top: none;
      border-left: none;
      border-right: none;
      border-bottom: $gray-border;
      border-radius: 0;
      width: 2.9em;
      .ant-input-number-input {
        padding: 0;
        font-size: 1.1em;
        font-weight: 100;
        text-align: right;
      }
    }
  }
}
